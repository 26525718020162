.study-abroad {
    overflow-x: hidden;
    &-header {
        background: linear-gradient(300.39deg, rgba(0, 0, 0, 0.6) 0.63%, rgba(0, 0, 0, 0) 18.44%), url(../images/study.png);
        height: 70vh;
        width: 100vw;
        overflow-x: hidden;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-origin: top left;
        position: relative; 
        .container { 
            height: 100%;   
            .title-btn {
                position: absolute; 
                bottom: 0; 
            }
            button {
                position: absolute; 
                bottom: -5rem; 
                z-index: 100;
            }
        }
    }

    .benefits {
        background: linear-gradient(300.39deg, rgba(0, 0, 0, 0.6) 0.63%, rgba(0, 0, 0, 0) 18.44%), url(../images/study.png);
        font-family: $primary-font;
        height: 70vh;
        width: 100vw;
        // overflow-x: hidden;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-origin: top left;
        position: relative; 
        .container {
            height: 100%;
        }
        .benefit-btn {
            position: absolute;
            bottom: -5rem;
            left: 0;
            z-index: 100;
        }
        &-box { 
            background: #FFFFFF;
            box-shadow: 0px 10px 45px -2px rgba(36, 36, 36, 0.03);
            border-radius: 40px;  
            h6 {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px; 
                letter-spacing: -0.02em; 
                color: #000000;}
            &-text {
                h2 {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 32.2603px;
                    line-height: 40px; 
                    letter-spacing: -0.02em; 
                    color: #000000;

                }
                p {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px; 
                    text-transform: capitalize; 
                    color: #060606; 
                }
            } 
        }
    }

    &-introduction {
        h3 {
            font-style: normal;
            font-weight: 700;
            font-size: 38.2603px;
            line-height: 52px; 
            letter-spacing: -0.02em; 
            color: #000000;
            font-family: $primary-font
        }
        p {
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 32px;  
            text-transform: capitalize; 
            color: #060606;
            font-family: $primary-font;
        }
    }
    &-card-section {
        

        .country-card-box {
            .country-card {
                border-radius: 5px;
                &-header {
                    min-height: 80px;
                    img {
                        width: 39px;
                    }
                    h4 {
                        text-transform: uppercase; 
                        font-style: normal;
                        font-weight: 700;
                        font-size: 17px;
                        line-height: 27px;
                        letter-spacing: -0.02em;
                        font-family: $primary-font;
                    }
                }

                &-body {
                    ul {
                        li {
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16.84px;
                            line-height: 50px; 
                            letter-spacing: -0.02em;
                            font-family: $primary-font;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 576px) {
    .study-abroad { 
        &-header { 
            .container { 
                .title-btn {
                    left: 10%;
                    bottom: 15vh;
                }
            }
        }
    
        &-introduction { 
            h3 { 
                font-size: 32px;
                line-height: 40px;  
            }
            p { 
                font-size: 15px;
                line-height: 30px;   
            }
        }
        &-card-section {
             
            .country-card-box {
                min-height: 300px;
                .country-card {
                    border-radius: 5px;
                    &-header {
                        min-height: 60px;
                        img {
                            width: 39px;
                        }
                        h4 { 
                            font-size: 17px;
                            line-height: 27px; 
                        }
                    }
    
                    &-body {
                        ul {
                            li { 
                                font-size: 16px;
                                line-height: 50px;  
                            }
                        }
                    }
                }
            }
        }
    }
}
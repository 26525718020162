// fonts 

$primary-font: 'Satoshi', sans-serif;
$secondary-font: 'Montserrat', sans-serif;
$filson: 'Filson Pro', sans-serif;
$nunito: 'Nunito', sans-serif;


//colors

$main-blue: #006B8B;
$main-yellow: #ECA633;
$black-text: #060606;
$main-green : #1FAF38;

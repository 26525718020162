.certification {
    font-family: $primary-font;
    background: rgba(242, 252, 255, 0.4);
    h2 {
        font-style: normal;
        font-weight: 700;
        font-size: 35.2603px;
        line-height: 45px; 
        letter-spacing: -0.02em; 
        color: #000000;

    }
    p {
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 24px;
        text-align: center;
        text-transform: capitalize; 
        color: #060606; 
    }
}
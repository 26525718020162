.info-section {
  background-color: #006B8B;
  color: #fff;
  font-family: "Satoshi", sans-serif;
  z-index: 1000;
}
.info-section img {
  width: 13px;
}
.info-section span {
  font-size: 14px;
  font-weight: 700;
  line-height: 21.6px;
  color: #fff;
  margin-bottom: 0;
}

.nav {
  background-color: #fff;
  min-height: 80px;
  border-bottom: 3px solid #ECA633;
  z-index: 1000;
}
.nav .logo img {
  width: 50px;
}
.nav-list ul {
  list-style-type: none;
  margin-bottom: 0;
  padding-left: 0;
}
.nav-list ul li {
  display: inline-block;
  margin-left: 25px;
}
.nav-list ul li a {
  color: #341900;
  text-decoration: none;
  font-size: 14px;
  line-height: 19px;
  text-transform: capitalize;
  font-weight: 400;
  font-family: "Satoshi", sans-serif;
}
.nav-list ul li a button {
  background-color: #ECA633;
  padding: 10px 20px;
  min-width: 70px;
  min-height: 30px;
  font-size: 14px;
  color: white;
  border: 1px solid #ECA633;
  outline: none;
}
.nav .hamburg {
  cursor: pointer;
  display: inline-block;
  position: relative;
  margin: 10px 5px;
  z-index: 20;
  margin-left: 15px;
  background-color: transparent;
}
.nav .hamburg span {
  background-color: #ECA633;
  display: block;
  height: 0.133em;
  margin-bottom: 0.28em;
  position: relative;
  top: 0;
  transition: all 0.3s ease-in-out;
}
.nav .hamburg span:nth-of-type(1) {
  width: 1.25em;
  margin-left: 2px;
}
.nav .hamburg span:nth-of-type(2) {
  width: 1.8em;
}
.nav .hamburg span:nth-of-type(3) {
  width: 1.5em;
  margin-left: -5px;
}

.mobile-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 120vh;
  background-color: #006B8B;
  z-index: 10000;
  padding: 20px;
  border-top: 3px solid #fff;
  border-bottom: 3px solid #fff;
  transform: translateX(-100%);
  transition: all 0.3s ease-in;
}
.mobile-nav .close-hamburg {
  cursor: pointer;
  display: inline-block;
  position: relative;
  margin: 10px 5px;
  margin-left: 15px;
  background-color: transparent;
  color: #ECA633;
}
.mobile-nav .close-hamburg div span {
  background-color: #ECA633;
  display: block;
  height: 0.133em;
  margin-bottom: 0em;
  position: relative;
  top: 0;
  transition: all 0.3s ease-in-out;
}
.mobile-nav .close-hamburg div span:nth-of-type(1) {
  width: 1.25em;
  transform: rotateZ(135deg);
}
.mobile-nav .close-hamburg div span:nth-of-type(2) {
  width: 1.25em;
  transform: rotateZ(-115deg);
}
.mobile-nav-content {
  font-weight: 600;
  letter-spacing: -1px;
  color: #fff;
}
.mobile-nav-content-links ul {
  padding-left: 0;
  list-style-type: none;
}
.mobile-nav-content-links ul li {
  margin-top: 5px;
}
.mobile-nav-content-links ul li a {
  text-decoration: none;
  color: #fff;
  font-size: 22px;
}
.mobile-nav-content-contact .location img {
  width: 35px;
}
.mobile-nav-content-contact .location p span {
  display: block;
  font-family: "Nunito", sans-serif;
  font-weight: 600;
  font-size: 13.2333px;
  line-height: 18px;
  color: #fff;
}
.mobile-nav-content-contact-social-icons a img {
  width: 20px;
  opacity: 0.95;
}
.mobile-nav-content-contact-number span {
  font-size: 15px;
  line-height: 22px;
}
.mobile-nav-content-contact-chat img {
  width: 40px;
}
.mobile-nav-content-contact-chat p {
  font-size: 15px;
  line-height: 22px;
}

.mobile-nav.active {
  transform: translateX(0);
  transition: all 0.3s ease-in;
}

.stats-section {
  background: rgba(242, 252, 255, 0.4);
}
.stats-section-box h6 {
  font-weight: 900;
  font-size: 24px;
  line-height: 32px;
  text-transform: capitalize;
  color: #000D11;
  font-family: "Satoshi", sans-serif;
}
.stats-section-box p {
  font-weight: 400;
  font-size: 14.25px;
  line-height: 19px;
  text-transform: capitalize;
  color: #000D11;
  font-family: "Satoshi", sans-serif;
}

.footer {
  background-color: #fff;
  text-align: center;
  min-height: 420px;
}
.footer-list {
  list-style-type: none;
}
.footer-list li {
  display: inline-block;
  margin: 10px 20px;
}
.footer-list li a {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #000000;
  text-decoration: none;
  font-family: "Filson Pro", sans-serif;
}
.footer .location img {
  width: 45px;
}
.footer .location p span {
  display: block;
  font-family: "Nunito", sans-serif;
  font-weight: 600;
  font-size: 13.2333px;
  line-height: 18px;
  color: #000000;
}
.footer-social-icons a img {
  width: 22.67px;
  opacity: 0.95;
}
.footer-number span {
  font-family: "Satoshi", sans-serif;
  font-weight: 700;
  font-size: 15px;
  line-height: 22px;
  color: #000000;
}
.footer-chat img {
  width: 50px;
}
.footer-chat p {
  font-family: "Satoshi", sans-serif;
  font-weight: 700;
  font-size: 15px;
  line-height: 22px;
  color: #000000;
}
.footer-email a {
  font-family: "Satoshi", sans-serif;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  color: #000000;
  text-decoration: none;
}

@media (max-width: 576px) {
  .footer {
    background-color: #fff;
    text-align: center;
    min-height: 350px;
  }
  .footer-list li {
    margin: 10px 15px;
  }
  .footer-list li a {
    font-size: 15px;
    line-height: 24px;
  }
  .footer .location img {
    width: 35px;
  }
  .footer .location p span {
    display: block;
    font-family: "Nunito", sans-serif;
    font-weight: 600;
    font-size: 13.2333px;
    line-height: 18px;
    color: #000000;
  }
  .footer-social-icons a img {
    width: 20px;
    opacity: 0.95;
  }
  .footer-number span {
    font-size: 15px;
    line-height: 22px;
  }
  .footer-chat img {
    width: 40px;
  }
  .footer-chat p {
    font-size: 15px;
    line-height: 22px;
  }
}
.about {
  overflow-x: hidden;
}
.about-header {
  background: linear-gradient(300.39deg, rgba(0, 0, 0, 0.6) 0.63%, rgba(0, 0, 0, 0) 18.44%), url(../images/uk2.jpg);
  height: 75vh;
  width: 100vw;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-origin: center left;
}
.about-header .container {
  position: relative;
  height: 100%;
}
.about-header .container .about-btn {
  position: absolute;
  z-index: 100;
  left: 0;
  bottom: -2rem;
}
.about .who-we-are h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 38.2603px;
  line-height: 52px;
  letter-spacing: -0.02em;
  color: #000000;
  font-family: "Satoshi", sans-serif;
}
.about .who-we-are p {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 32px;
  text-transform: capitalize;
  color: #060606;
  font-family: "Satoshi", sans-serif;
}
.about .team-section-text p {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 32px;
  color: #060606;
  font-family: "Satoshi", sans-serif;
}
.about .team-section-image {
  position: relative;
}
.about .team-section-image .title-btn {
  position: absolute;
  top: -30px;
  right: 10px;
}
.about .client-satisfaction h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 45px;
  text-transform: capitalize;
  font-family: "Satoshi", sans-serif;
}
.about .client-satisfaction-image {
  position: relative;
  text-align: right;
}
.about .client-satisfaction-image .title-btn {
  position: absolute;
  bottom: -20px;
  left: 0;
}

@media (max-width: 576px) {
  .about-header .container .about-btn {
    left: 10%;
    bottom: 15vh;
  }
  .about .who-we-are h3 {
    font-size: 32px;
    line-height: 40px;
  }
  .about .who-we-are p {
    font-size: 15px;
    line-height: 30px;
  }
  .about .team-section-text p {
    font-size: 15px;
    line-height: 30px;
  }
  .about .client-satisfaction h3 {
    font-size: 27px;
    line-height: 40px;
  }
}
.study-abroad {
  overflow-x: hidden;
}
.study-abroad-header {
  background: linear-gradient(300.39deg, rgba(0, 0, 0, 0.6) 0.63%, rgba(0, 0, 0, 0) 18.44%), url(../images/study.png);
  height: 70vh;
  width: 100vw;
  overflow-x: hidden;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-origin: top left;
  position: relative;
}
.study-abroad-header .container {
  height: 100%;
}
.study-abroad-header .container .title-btn {
  position: absolute;
  bottom: 0;
}
.study-abroad-header .container button {
  position: absolute;
  bottom: -5rem;
  z-index: 100;
}
.study-abroad .benefits {
  background: linear-gradient(300.39deg, rgba(0, 0, 0, 0.6) 0.63%, rgba(0, 0, 0, 0) 18.44%), url(../images/study.png);
  font-family: "Satoshi", sans-serif;
  height: 70vh;
  width: 100vw;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-origin: top left;
  position: relative;
}
.study-abroad .benefits .container {
  height: 100%;
}
.study-abroad .benefits .benefit-btn {
  position: absolute;
  bottom: -5rem;
  left: 0;
  z-index: 100;
}
.study-abroad .benefits-box {
  background: #FFFFFF;
  box-shadow: 0px 10px 45px -2px rgba(36, 36, 36, 0.03);
  border-radius: 40px;
}
.study-abroad .benefits-box h6 {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #000000;
}
.study-abroad .benefits-box-text h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 32.2603px;
  line-height: 40px;
  letter-spacing: -0.02em;
  color: #000000;
}
.study-abroad .benefits-box-text p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-transform: capitalize;
  color: #060606;
}
.study-abroad-introduction h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 38.2603px;
  line-height: 52px;
  letter-spacing: -0.02em;
  color: #000000;
  font-family: "Satoshi", sans-serif;
}
.study-abroad-introduction p {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 32px;
  text-transform: capitalize;
  color: #060606;
  font-family: "Satoshi", sans-serif;
}
.study-abroad-card-section .country-card-box .country-card {
  border-radius: 5px;
}
.study-abroad-card-section .country-card-box .country-card-header {
  min-height: 80px;
}
.study-abroad-card-section .country-card-box .country-card-header img {
  width: 39px;
}
.study-abroad-card-section .country-card-box .country-card-header h4 {
  text-transform: uppercase;
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 27px;
  letter-spacing: -0.02em;
  font-family: "Satoshi", sans-serif;
}
.study-abroad-card-section .country-card-box .country-card-body ul li {
  font-style: normal;
  font-weight: 400;
  font-size: 16.84px;
  line-height: 50px;
  letter-spacing: -0.02em;
  font-family: "Satoshi", sans-serif;
}

@media (max-width: 576px) {
  .study-abroad-header .container .title-btn {
    left: 10%;
    bottom: 15vh;
  }
  .study-abroad-introduction h3 {
    font-size: 32px;
    line-height: 40px;
  }
  .study-abroad-introduction p {
    font-size: 15px;
    line-height: 30px;
  }
  .study-abroad-card-section .country-card-box {
    min-height: 300px;
  }
  .study-abroad-card-section .country-card-box .country-card {
    border-radius: 5px;
  }
  .study-abroad-card-section .country-card-box .country-card-header {
    min-height: 60px;
  }
  .study-abroad-card-section .country-card-box .country-card-header img {
    width: 39px;
  }
  .study-abroad-card-section .country-card-box .country-card-header h4 {
    font-size: 17px;
    line-height: 27px;
  }
  .study-abroad-card-section .country-card-box .country-card-body ul li {
    font-size: 16px;
    line-height: 50px;
  }
}
.evaluate {
  overflow-x: hidden;
}
.evaluate-header {
  background: linear-gradient(300.39deg, rgba(0, 0, 0, 0.6) 0.63%, rgba(0, 0, 0, 0) 18.44%), url(../images/evaluate.jpg);
  height: 70vh;
  width: 100vw;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-origin: top left;
}
.evaluate-header .container {
  height: 100%;
  position: relative;
}
.evaluate-header .container .title-btn {
  position: absolute;
  bottom: -2rem;
}
.evaluate-form-box {
  background: #006B8B;
  color: #fff;
  border-radius: 7px;
}
.evaluate-form-box-header {
  text-align: center;
  font-family: "Satoshi", sans-serif;
}
.evaluate-form-box-header h5 {
  font-style: normal;
  font-weight: 700;
  font-size: 34.0341px;
  line-height: 51px;
  letter-spacing: -0.02em;
}
.evaluate-form-box-header p {
  font-style: normal;
  font-weight: 400;
  font-size: 20.6693px;
  line-height: 39px;
  text-align: center;
  text-transform: capitalize;
}
.evaluate-form-box-body select {
  padding: 14.3346px 28.6693px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4.30039px;
  border: none;
  color: #fff;
  font-family: "Satoshi", sans-serif;
  font-size: 18.0685px;
  line-height: 27px;
  text-transform: capitalize;
  opacity: 0.6;
}
.evaluate-form-box-body select option {
  background-color: #ECA633;
  font-style: normal;
  font-weight: 400;
  font-family: "Satoshi", sans-serif;
}
.evaluate-form-box-body select:focus {
  box-shadow: none;
}

.evaluate-modal-container {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: center;
}
.evaluate-modal-container .evaluate-modal {
  background-color: #fff;
  font-family: "Satoshi", sans-serif;
  color: #000;
  position: relative;
}
.evaluate-modal-container .evaluate-modal .close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #006B8B;
  font-size: 25px;
}
.evaluate-modal-container .evaluate-modal-header {
  background-color: rgba(236, 166, 51, 0.8);
  padding: 10px;
}
.evaluate-modal-container .evaluate-modal-header h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 35.2603px;
  line-height: 45px;
  letter-spacing: -0.02em;
  color: #fff;
}
.evaluate-modal-container .evaluate-modal-header p {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-transform: capitalize;
  color: #fff;
}
.evaluate-modal-container .evaluate-modal-body p {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-transform: capitalize;
}
.evaluate-modal-container .evaluate-modal-body form label {
  margin-bottom: 2px;
}
.evaluate-modal-container .evaluate-modal-body form input {
  padding: 15px 18px;
  width: 100%;
  border-radius: 8px;
  background-color: rgba(237, 237, 237, 0.7);
  font-size: 16px;
  outline: none;
  color: rgba(0, 0, 0, 0.45);
  line-height: 28.4px;
  font-weight: 500;
  border: 1px solid #f5f5f5;
  font-family: "Satoshi", sans-serif;
}
.evaluate-modal-container .evaluate-modal-body form input:focus {
  box-shadow: none;
}

@media (max-width: 576px) {
  .evaluate-header .container .title-btn {
    left: 10%;
    bottom: 15vh;
  }
  .evaluate-form-box-header h5 {
    font-size: 30.0341px;
    line-height: 40px;
  }
  .evaluate-form-box-header p {
    font-size: 17.6693px;
    line-height: 32px;
  }
  .evaluate-form-box-body select {
    font-size: 16px;
    line-height: 27px;
  }
  .evaluate-modal-container {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    display: flex;
    align-items: center;
  }
  .evaluate-modal-container .evaluate-modal {
    background-color: #fff;
    font-family: "Satoshi", sans-serif;
    color: #000;
    position: relative;
  }
  .evaluate-modal-container .evaluate-modal .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    color: red;
    font-size: 25px;
  }
  .evaluate-modal-container .evaluate-modal-header {
    background-color: rgba(236, 166, 51, 0.8);
    padding: 10px;
  }
  .evaluate-modal-container .evaluate-modal-header h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 25.2603px;
    line-height: 35px;
  }
  .evaluate-modal-container .evaluate-modal-header p {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }
  .evaluate-modal-container .evaluate-modal-body p {
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
  }
  .evaluate-modal-container .evaluate-modal-body form input {
    padding: 15px 15px;
    font-size: 16px;
    line-height: 23.4px;
  }
  .evaluate-modal-container .evaluate-modal-body form input:focus {
    box-shadow: none;
  }
}
.apply {
  overflow-x: hidden;
}
.apply-header {
  background: linear-gradient(300.39deg, rgba(0, 0, 0, 0.6) 0.63%, rgba(0, 0, 0, 0) 18.44%), url(../images/apply1.jpg);
  height: 70vh;
  width: 100vw;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-origin: top left;
}
.apply-header .container {
  height: 100%;
  position: relative;
}
.apply-header .container .title-btn {
  position: absolute;
  bottom: -2rem;
}
.apply-form-box {
  background: #ECA633;
  color: #fff;
  border-radius: 7px;
}
.apply-form-box-header {
  text-align: center;
  font-family: "Satoshi", sans-serif;
}
.apply-form-box-header h5 {
  font-style: normal;
  font-weight: 700;
  font-size: 34.0341px;
  line-height: 51px;
  letter-spacing: -0.02em;
}
.apply-form-box-header p {
  font-style: normal;
  font-weight: 400;
  font-size: 20.6693px;
  line-height: 39px;
  text-align: center;
  text-transform: capitalize;
}
.apply-form-box-body select {
  padding: 14.3346px 28.6693px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4.30039px;
  border: none;
  color: #fff;
  font-family: "Satoshi", sans-serif;
  font-size: 18.0685px;
  line-height: 27px;
  text-transform: capitalize;
}
.apply-form-box-body select option {
  background-color: #006B8B;
  font-style: normal;
  font-weight: 400;
  font-family: "Satoshi", sans-serif;
}
.apply-form-box-body select:focus {
  box-shadow: none;
}
.apply-pay-box {
  background: #FFFFFF;
  border: 0.2px solid #ECA633;
  border-radius: 8.48px;
  filter: drop-shadow(-5px 10px 45px rgba(0, 0, 0, 0.03));
}
.apply-pay-box-header {
  font-family: "Satoshi", sans-serif;
}
.apply-pay-box-header img {
  width: 65px;
  height: 65px;
  border-radius: 50%;
}
.apply-pay-box-header h5 {
  font-style: normal;
  font-weight: 700;
  font-size: 27px;
  line-height: 47px;
  letter-spacing: -0.02em;
  color: #1FAF38;
}
.apply-pay-box-header h6 {
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;
  letter-spacing: -0.02em;
  color: #161616;
}
.apply-pay-box-header p {
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #585858;
}
.apply-pay-box-header .cash-box span {
  background-color: #1FAF38;
  color: #fff;
  font-weight: 700;
  font-size: 35px;
  line-height: 47px;
  letter-spacing: -0.02em;
  padding: 7px 25px;
}
.apply-pay-box-body {
  font-family: "Satoshi", sans-serif;
}
.apply-pay-box-body p {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #000000;
}
.apply-pay-box-body ul li {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 35px;
  letter-spacing: -0.02em;
}

@media (max-width: 576px) {
  .apply-header .container .title-btn {
    left: 10%;
    bottom: 15vh;
  }
  .apply-form-box-header h5 {
    font-size: 30.0341px;
    line-height: 40px;
  }
  .apply-form-box-header p {
    font-size: 17.6693px;
    line-height: 32px;
  }
  .apply-form-box-body select {
    font-size: 16px;
    line-height: 27px;
  }
  .apply-pay-box {
    background: #FFFFFF;
    border: 0.2px solid #ECA633;
    border-radius: 8.48px;
    filter: drop-shadow(-5px 10px 45px rgba(0, 0, 0, 0.03));
  }
  .apply-pay-box-header {
    font-family: "Satoshi", sans-serif;
  }
  .apply-pay-box-header img {
    width: 45px;
    height: 45px;
  }
  .apply-pay-box-header h5 {
    font-size: 24px;
    line-height: 47px;
  }
  .apply-pay-box-header h6 {
    font-size: 20px;
    line-height: 27px;
  }
  .apply-pay-box-header p {
    font-size: 18px;
    line-height: 18px;
  }
  .apply-pay-box-header .cash-box span {
    background-color: #1FAF38;
    color: #fff;
    font-weight: 700;
    font-size: 30px;
    line-height: 47px;
    letter-spacing: -0.02em;
    padding: 7px 25px;
  }
  .apply-pay-box-body {
    font-family: "Satoshi", sans-serif;
  }
  .apply-pay-box-body p {
    font-size: 16px;
    line-height: 24px;
  }
  .apply-pay-box-body ul li {
    font-size: 16px;
    line-height: 35px;
  }
}
.home {
  overflow-x: hidden;
}
.home-header {
  min-height: 85vh;
  width: 100vw;
  position: relative;
}
.home-header .bg {
  background-color: #006B8B;
}
.home-header .access-btn {
  z-index: 100;
  min-width: 250px;
  margin-left: 150px;
  position: absolute;
  background-color: #ECA633;
  outline: none;
  border: none;
  color: #fff;
  padding: 10px 20px;
  text-transform: uppercase;
  font-size: 26px;
}
.home-header .access-btn::after {
  position: absolute;
  content: "";
  width: 50%;
  border-top: 1.3px solid #ECA633;
  border-left: 1.3px solid #ECA633;
  height: 80%;
  top: -15%;
  left: -20px;
}
.home-header .access-btn::before {
  position: absolute;
  content: "";
  width: 50%;
  border-bottom: 1.3px solid #ECA633;
  border-right: 1.3px solid #ECA633;
  height: 80%;
  bottom: -15%;
  right: -20px;
}
.home-header .swiper-horizontal > .swiper-pagination-bullets,
.home-header .swiper-pagination-bullets.swiper-pagination-horizontal,
.home-header .swiper-pagination-custom,
.home-header .swiper-pagination-fraction {
  bottom: 10px;
  left: 0;
  width: 100%;
}
.home-header .swiper-button-next {
  display: none;
}
.home-header .swiper-button-next::after {
  display: none;
}
.home-header .swiper-button-prev {
  display: none;
}
.home-header .swiper-button-prev::after {
  display: none;
}
.home-header .swiper-pagination {
  position: absolute;
  text-align: right;
  padding: 30px 80px;
}
.home-header .swiper-pagination-bullet {
  width: 23px;
  height: 23px;
  background: #ffffff;
  opacity: 0.3;
}
.home-header .swiper-pagination-bullet-active {
  opacity: 1;
}
.home-header-blue-section {
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1) 30%, #006b8b 30%, #006b8b 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 80%, #006b8b 80%);
  height: 85vh;
  color: #fff;
  padding-top: 20vh;
  position: relative;
}
.home-header-blue-section h1 {
  font-style: normal;
  font-weight: 900;
  font-size: 32.3787px;
  line-height: 42px;
  text-transform: uppercase;
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
}
.home-header-blue-section p {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-transform: capitalize;
  color: #ffffff;
}
.home-header-blue-section .blue-ring {
  position: absolute;
  width: 60%;
  height: 30%;
  bottom: 0;
  left: 0;
}
.home-header-blue-section .cert-badge {
  position: absolute;
  width: 80px;
  bottom: 40px;
  right: 40px;
}
.home-header-blue-section .study-ring {
  position: absolute;
  top: -70px;
  left: -30px;
}
.home-header-carousel {
  position: relative;
}
.home-header-carousel .slide-div-one {
  background: linear-gradient(300.39deg, rgba(0, 0, 0, 0.6) 0.63%, rgba(0, 0, 0, 0) 18.44%), url(../images/canada1.jpg);
  overflow-x: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-origin: top left;
  height: 100%;
}
.home-header-carousel .slide-div-two {
  background: linear-gradient(300.39deg, rgba(0, 0, 0, 0.6) 0.63%, rgba(0, 0, 0, 0) 18.44%), url(../images/uk3.jpg);
  overflow-x: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-origin: top left;
  height: 100%;
}
.home-header-carousel .slide-div-three {
  background: linear-gradient(300.39deg, rgba(0, 0, 0, 0.6) 0.63%, rgba(0, 0, 0, 0) 18.44%), url(../images/australia.jpg);
  overflow-x: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-origin: top left;
  height: 100%;
}
.home-header-mobile {
  min-height: 75vh;
  width: 100vw;
}
.home-header-mobile-carousel {
  position: relative;
  height: 75vh;
  display: flex;
  justify-content: center;
}
.home-header-mobile-carousel-text {
  position: absolute;
  top: 40%;
  z-index: 100;
}
.home-header-mobile-carousel-text-box {
  background-color: rgba(0, 0, 0, 0.6);
  min-height: 200px;
  color: #fff;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.home-header-mobile-carousel-text-box h1 {
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  line-height: 40px;
  text-transform: uppercase;
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
}
.home-header-mobile-carousel-text-box p {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-transform: capitalize;
  color: #ffffff;
}
.home-header-mobile-carousel-text-box .access-btn {
  background-color: #ECA633;
  outline: none;
  border: none;
  color: #fff;
  padding: 10px;
  text-transform: uppercase;
  font-size: 16px;
  position: relative;
  width: 150px;
}
.home-header-mobile-carousel .slide-div-one {
  background: linear-gradient(300.39deg, rgba(0, 0, 0, 0.6) 0.63%, rgba(0, 0, 0, 0) 18.44%), url(../images/poland.png);
  overflow-x: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-origin: top left;
  height: 100%;
}
.home-header-mobile-carousel .slide-div-two {
  background: linear-gradient(300.39deg, rgba(0, 0, 0, 0.6) 0.63%, rgba(0, 0, 0, 0) 18.44%), url(../images/australia.png);
  overflow-x: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-origin: top left;
  height: 100%;
}
.home-header-mobile-carousel .slide-div-three {
  background: linear-gradient(300.39deg, rgba(0, 0, 0, 0.6) 0.63%, rgba(0, 0, 0, 0) 18.44%), url(../images/usa.png);
  overflow-x: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-origin: top left;
  height: 100%;
}
.home-header-mobile-carousel .swiper-horizontal > .swiper-pagination-bullets,
.home-header-mobile-carousel .swiper-pagination-bullets.swiper-pagination-horizontal,
.home-header-mobile-carousel .swiper-pagination-custom,
.home-header-mobile-carousel .swiper-pagination-fraction {
  bottom: 10px;
  left: 0;
  width: 100%;
}
.home-header-mobile-carousel .swiper-button-next {
  display: none;
}
.home-header-mobile-carousel .swiper-button-next::after {
  display: none;
}
.home-header-mobile-carousel .swiper-button-prev {
  display: none;
}
.home-header-mobile-carousel .swiper-button-prev::after {
  display: none;
}
.home-header-mobile-carousel .swiper-pagination {
  position: absolute;
  top: 0;
  text-align: right;
  padding: 15px 30px;
}
.home-header-mobile-carousel .swiper-pagination-bullet {
  width: 17px;
  height: 17px;
  background: #ffffff;
  opacity: 0.3;
}
.home-header-mobile-carousel .swiper-pagination-bullet-active {
  opacity: 1;
}
.home .get-started {
  font-family: "Satoshi", sans-serif;
}
.home .get-started h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 35.2603px;
  line-height: 45px;
  letter-spacing: -0.02em;
  color: #000000;
}
.home .get-started p {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
  text-align: center;
  text-transform: capitalize;
  color: #060606;
}
.home .get-started .video-box {
  background-color: rgb(238, 234, 234);
  border-radius: 34.4115px;
  min-height: 400px;
}
.home .benefits {
  background-color: #fafafa;
  font-family: "Satoshi", sans-serif;
}
.home .benefits .benefit-btn {
  position: absolute;
  top: -5rem;
  right: 0;
}
.home .benefits-box {
  background: #ffffff;
  box-shadow: 0px 10px 45px -2px rgba(36, 36, 36, 0.03);
  border-radius: 40px;
}
.home .benefits-box h6 {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #000000;
}
.home .benefits-box-text h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 32.2603px;
  line-height: 40px;
  letter-spacing: -0.02em;
  color: #000000;
}
.home .benefits-box-text p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-transform: capitalize;
  color: #060606;
}
.home .free-section {
  background-color: #fafafa;
}
.home .free-section-container .free-card {
  background: #ffffff;
  box-shadow: 0px 10px 45px rgba(36, 36, 36, 0.03);
  border-radius: 40px;
}
.home .free-section-container .free-card img {
  border-radius: 40px 40px 0 0;
}
.home .free-section-container .free-card-body {
  font-family: "Satoshi", sans-serif;
}
.home .free-section-container .free-card-body h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 55px;
  letter-spacing: -0.02em;
  color: #0d0d0d;
}
.home .free-section-container .free-card-body ul li {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-transform: capitalize;
  color: #0d0d0d;
}
.home .access-card {
  font-family: "Satoshi", sans-serif;
  position: relative;
  padding-top: 150px;
  background-color: #ECA633;
  box-shadow: 0px 10px 45px -10px rgba(36, 36, 36, 0.03);
  border-radius: 40px;
  color: #fff;
}
.home .access-card img.hand {
  width: 50%;
  position: absolute;
  top: 0;
  right: 0;
}
.home .access-card h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 55px;
  letter-spacing: -0.02em;
}
.home .access-card p {
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  text-transform: capitalize;
}
.home .procedure {
  font-family: "Satoshi", sans-serif;
}
.home .procedure-header h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 35.2603px;
  line-height: 45px;
  letter-spacing: -0.02em;
  color: #000000;
}
.home .procedure-header p {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
  text-align: center;
  text-transform: capitalize;
  color: #060606;
}
.home .procedure-body {
  background-color: rgba(236, 166, 51, 0.06);
}
.home .procedure-body-box .img-box {
  position: relative;
}
.home .procedure-body-box .img-box img {
  width: 100%;
  position: relative;
}
.home .procedure-body-box .img-box .step {
  position: absolute;
  right: -10%;
  top: -35px;
}
.home .procedure-body-box .mySwiper h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: -0.02em;
  color: #000000;
}
.home .procedure-body-box .mySwiper p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-transform: capitalize;
  color: #060606;
}
.home .procedure-body-box .mySwiper .swiper-horizontal > .swiper-pagination-bullets,
.home .procedure-body-box .mySwiper .swiper-pagination-bullets.swiper-pagination-horizontal,
.home .procedure-body-box .mySwiper .swiper-pagination-custom,
.home .procedure-body-box .mySwiper .swiper-pagination-fraction {
  bottom: 10px;
  left: 0;
  width: 100%;
}
.home .procedure-body-box .mySwiper .swiper-button-next::after {
  display: none;
}
.home .procedure-body-box .mySwiper .swiper-button-prev {
  display: none;
}
.home .procedure-body-box .mySwiper .swiper-button-prev::after {
  display: none;
}
.home .procedure-body-box .mySwiper .swiper-pagination {
  display: flex;
  align-items: flex-start;
}
.home .procedure-body-box .mySwiper .swiper-pagination-bullet {
  width: 14px;
  height: 14px;
  background: #000;
  opacity: 0.08;
}
.home .procedure-body-box .mySwiper .swiper-pagination-bullet-active {
  background-color: #ECA633;
  opacity: 1;
}

@media (max-width: 771px) {
  .home .procedure-body-box .img-box img {
    width: 80%;
  }
  .home .procedure-body-box .img-box .step {
    right: 10%;
  }
  .home .procedure-body-box .mySwiper .swiper-pagination {
    margin-left: 0;
  }
  .home .procedure-body-box .mySwiper .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background: #000;
    opacity: 0.08;
  }
  .home .procedure-body-box .mySwiper .swiper-pagination-bullet-active {
    background-color: #ECA633;
    opacity: 1;
  }
  .home .procedure-body-box .mySwiper .swiper-horizontal > .swiper-pagination-bullets,
.home .procedure-body-box .mySwiper .swiper-pagination-bullets.swiper-pagination-horizontal,
.home .procedure-body-box .mySwiper .swiper-pagination-custom,
.home .procedure-body-box .mySwiper .swiper-pagination-fraction {
    bottom: 0;
    left: 0;
    width: 100%;
  }
}
.certification {
  font-family: "Satoshi", sans-serif;
  background: rgba(242, 252, 255, 0.4);
}
.certification h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 35.2603px;
  line-height: 45px;
  letter-spacing: -0.02em;
  color: #000000;
}
.certification p {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
  text-align: center;
  text-transform: capitalize;
  color: #060606;
}

.contact {
  font-family: "Satoshi", sans-serif;
}
.contact-header h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 35.2603px;
  line-height: 45px;
  letter-spacing: -0.02em;
  color: #000000;
}
.contact-header p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-transform: capitalize;
  color: #060606;
}
.contact-form form input, .contact-form form textarea {
  padding: 23px 18px;
  width: 100%;
  border-radius: 8px;
  background-color: rgba(237, 237, 237, 0.7);
  font-size: 16px;
  outline: none;
  color: rgba(0, 0, 0, 0.45);
  line-height: 28.4px;
  font-weight: 500;
  border: 1px solid #f5f5f5;
  font-family: "Satoshi", sans-serif;
}
.contact-form-box {
  background: #006B8B;
  color: #fff;
  border-radius: 7px;
}
.contact-form-box-header {
  text-align: center;
  font-family: "Satoshi", sans-serif;
}
.contact-form-box-header h5 {
  font-style: normal;
  font-weight: 700;
  font-size: 35.0341px;
  line-height: 51px;
  letter-spacing: -0.02em;
}
.contact-form-box-header p {
  font-style: normal;
  font-weight: 400;
  font-size: 15.6693px;
  line-height: 24px;
  text-align: center;
  text-transform: capitalize;
}
.contact-form-box-body select {
  padding: 14.3346px 28.6693px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4.30039px;
  border: none;
  color: #fff;
  font-family: "Satoshi", sans-serif;
  font-size: 16.0685px;
  line-height: 27px;
  text-transform: capitalize;
  opacity: 0.6;
}
.contact-form-box-body select option {
  background-color: #ECA633;
  font-style: normal;
  font-weight: 400;
  font-family: "Satoshi", sans-serif;
}
.contact-form-box-body select:focus {
  box-shadow: none;
}

.testimonials {
  font-family: "Satoshi", sans-serif;
}
.testimonials-header h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 35.2603px;
  line-height: 45px;
  letter-spacing: -0.02em;
  color: #000000;
}
.testimonials-header p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-transform: capitalize;
  color: #060606;
}
.testimonials-body-box {
  background: #FFFFFF;
  border: 0.465934px solid #ECA633;
  border-radius: 13.978px;
  position: relative;
}
.testimonials-body-box-text h6 {
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #000000;
  font-family: "Satoshi", sans-serif;
}
.testimonials-body-box-text p {
  font-style: normal;
  font-weight: 400;
  font-size: 15.4389px;
  line-height: 21px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #000000;
  font-family: "Satoshi", sans-serif;
}
.testimonials .swiper-button-next {
  background: #ECA633;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.testimonials .swiper-button-next::after {
  font-size: 13px;
  color: #000;
  background-color: #ECA633;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #fff;
}
.testimonials .swiper-button-prev {
  background: rgba(144, 144, 144, 0.2);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  z-index: 100;
}
.testimonials .swiper-button-prev::after {
  font-size: 13px;
  color: #000;
  background-color: rgba(144, 144, 144, 0.2);
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #fff;
}
.testimonials .swiper-pagination {
  display: none;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

.main header {
  font-family: "Satoshi", sans-serif;
}

.main-btn {
  outline: none;
  border: none;
  padding: 10px 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  text-transform: capitalize;
  font-family: "Satoshi", sans-serif;
}

.big-btn {
  outline: none;
  border: none;
  padding: 10px 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  text-transform: capitalize;
  min-height: 60px;
  min-width: 240px;
  font-family: "Satoshi", sans-serif;
  border-radius: 4px;
}

.tx-yellow {
  color: #ECA633;
}

.dropdown-menu {
  left: auto !important;
  top: 95% !important;
  border-radius: 0 !important;
  border: 0;
}

.dropdown:hover .dropdown-menu, .dropdown:hover .dropdown-menu.sub-dropdown {
  display: block;
  margin-top: 0;
  color: #ECA633;
}

.nav-item.dropdown .dropdown-menu a, .nav-item.online-dropdown .dropdown-menu a {
  padding: 13px;
  font-size: 13px;
  font-family: "Satoshi", sans-serif;
}

.title-btn {
  z-index: 100;
  min-width: 300px;
  position: relative;
  background-color: #ECA633;
  outline: none;
  border: none;
  color: #fff;
  padding: 10px;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  font-size: 26px;
  min-height: 55px;
  font-weight: 400;
  font-style: normal;
}
.title-btn::after {
  position: absolute;
  content: "";
  width: 50%;
  border-top: 1.3px solid #ECA633;
  border-left: 1.3px solid #ECA633;
  height: 80%;
  top: -15%;
  left: -20px;
}
.title-btn::before {
  position: absolute;
  content: "";
  width: 50%;
  border-bottom: 1.3px solid #ECA633;
  border-right: 1.3px solid #ECA633;
  height: 80%;
  bottom: -15%;
  right: -20px;
}

@media (max-width: 576px) {
  .title-btn {
    z-index: 100;
    min-width: 200px;
    position: relative;
    background-color: #ECA633;
    outline: none;
    border: none;
    color: #fff;
    padding: 10px;
    text-transform: uppercase;
    font-size: 20px;
    min-height: 45px;
  }
  .title-btn::after {
    position: absolute;
    content: "";
    width: 50%;
    border-top: 1.3px solid #ECA633;
    border-left: 1.3px solid #ECA633;
    height: 80%;
    top: -15%;
    left: -15px;
  }
  .title-btn::before {
    position: absolute;
    content: "";
    width: 50%;
    border-bottom: 1.3px solid #ECA633;
    border-right: 1.3px solid #ECA633;
    height: 80%;
    bottom: -15%;
    right: -15px;
  }
}
.error-page {
  background-color: #006B8B;
}
.error-page div {
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1) 30%, #006b8b 30%, #006b8b 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 80%, #006b8b 80%);
  min-height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #FFF;
}
.error-page h2 {
  font-size: 2.5rem;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-weight: 900;
}
.error-page h6 {
  font-family: "Satoshi", sans-serif;
  text-transform: capitalize;
  font-size: 1.5rem;
}
.error-page h6 a {
  color: #ECA633;
}

.back-to-top {
  background-color: #ECA633;
  width: 45px;
  height: 45px;
  display: block;
  border-radius: 50%;
  border: 1px solid #ECA633;
  z-index: 100;
  transition: all 0.3s ease-in;
}
.back-to-top svg {
  color: #fff;
  font-size: 20px;
  transition: all 0.3s ease-in;
}
.back-to-top:hover {
  scale: 1.05;
}
.back-to-top:hover svg {
  margin-top: -10px;
  font-size: 15px;
}

#myVideo {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.loader-box {
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  width: 20px;
  height: 20px;
  display: block;
  background-color: transparent;
  border-top: 2px solid #fff;
  border-bottom: 2px solid #fff;
  border-radius: 50%;
  -webkit-animation: loader 1s infinite;
          animation: loader 1s infinite;
}

@-webkit-keyframes loader {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes loader {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}/*# sourceMappingURL=style.css.map */
.testimonials {
    font-family: $primary-font;
    &-header {
        h2 {
            font-style: normal;
            font-weight: 700;
            font-size: 35.2603px;
            line-height: 45px; 
            letter-spacing: -0.02em; 
            color: #000000;
    
        }
        p {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px; 
            text-transform: capitalize; 
            color: #060606; 
        }
    } 
    &-body {
        // background-image: url(../images/testimonial-bg2.png);
        // overflow-x: hidden;
        // background-size: 90%;
        // background-repeat: no-repeat;
        // background-position: center;
        // background-origin: top center;
        &-box {
            background: #FFFFFF;
            border: 0.465934px solid #ECA633;
            border-radius: 13.978px;
            position: relative; 
            &-text {
                h6 {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 17px;
                    line-height: 24px; 
                    text-align: center;
                    letter-spacing: -0.01em; 
                    color: #000000;
                    font-family: $primary-font;
                }
                p {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 15.4389px;
                    line-height: 21px;
                    text-align: center;
                    letter-spacing: -0.01em; 
                    color: #000000;
                    font-family: $primary-font;
                }
            }
            
        }
    }

    .swiper-button-next { 
        background: #ECA633;
        border-radius: 50%;
        width: 50px;
        height: 50px;  
        border-radius: 50%;  
        &::after {
            font-size: 13px;
            color: #000;
            background-color: #ECA633; 
            width: 48px;
            height: 48px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 2px solid #fff;
        }
    }
    .swiper-button-prev { 
        background: rgba(144, 144, 144, 0.2);
        border-radius: 50%;
        width: 50px;
        height: 50px;  
        border-radius: 50%;   
        z-index: 100;
        &::after {
            font-size: 13px;
            color: #000;
            background-color: rgba(144, 144, 144, 0.2); 
            width: 48px;
            height: 48px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 2px solid #fff;
        }
    }
    .swiper-pagination { 
        display: none;
    }
}
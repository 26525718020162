.contact {
    font-family: $primary-font;
    &-header {
        h2 {
            font-style: normal;
            font-weight: 700;
            font-size: 35.2603px;
            line-height: 45px; 
            letter-spacing: -0.02em; 
            color: #000000;
    
        }
        p {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px; 
            text-transform: capitalize; 
            color: #060606; 
        }
    }
    &-form {
        form {
            input, textarea {
                padding: 23px 18px;
                width: 100%;
                border-radius: 8px;
                background-color: rgba(237, 237, 237, 0.7); 
                font-size: 16px;  
                outline: none; 
                color: rgba($color: #000000, $alpha: 0.45);
                line-height: 28.4px;
                font-weight: 500;
                border: 1px solid #f5f5f5;
                font-family: $primary-font;
            }
        }
        &-box {
            background: $main-blue; 
            color: #fff;
            border-radius: 7px;
            &-header {
                text-align: center;
                font-family: $primary-font;
                h5 {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 35.0341px;
                    line-height: 51px;
                    letter-spacing: -0.02em;
                }
                p { 
                    font-style: normal;
                    font-weight: 400;
                    font-size: 15.6693px;
                    line-height: 24px;
                    text-align: center;
                    text-transform: capitalize;
                }
            }
            
            &-body {
               select {
                padding: 14.3346px 28.6693px;
                background: rgba(255, 255, 255, 0.1);
                border-radius: 4.30039px;
                border: none;
                color: #fff;
                font-family: $primary-font; 
                font-size: 16.0685px;
                line-height: 27px; 
                text-transform: capitalize; 
                opacity: 0.6;
                option {
                    background-color:$main-yellow;
                    font-style: normal;
                    font-weight: 400;
                    font-family: $primary-font;
                } 
                &:focus {
                    box-shadow: none;
                } 
               } 
            }
        }
    }
}
.apply {
    overflow-x: hidden;
    &-header {
        background: linear-gradient(300.39deg, rgba(0, 0, 0, 0.6) 0.63%, rgba(0, 0, 0, 0) 18.44%), url(../images/apply1.jpg);
        height: 70vh;
        width: 100vw;
        // overflow-x: hidden;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-origin: top left;
        .container {
            height: 100%;
            position: relative;
            .title-btn {
                position: absolute; 
                bottom: -2rem;
            }
        }
    }

    &-form {
        &-box {
            background: $main-yellow; 
            color: #fff;
            border-radius: 7px;
            &-header {
                text-align: center;
                font-family: $primary-font;
                h5 {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 34.0341px;
                    line-height: 51px;
                    letter-spacing: -0.02em;
                }
                p { 
                    font-style: normal;
                    font-weight: 400;
                    font-size: 20.6693px;
                    line-height: 39px;
                    text-align: center;
                    text-transform: capitalize;
                }
            }
            
            &-body {
               select {
                padding: 14.3346px 28.6693px;
                background: rgba(255, 255, 255, 0.1);
                border-radius: 4.30039px;
                border: none;
                color: #fff;
                font-family: $primary-font; 
                font-size: 18.0685px;
                line-height: 27px; 
                text-transform: capitalize; 
                // opacity: 0.6;
                option {
                    background-color:$main-blue;
                    font-style: normal;
                    font-weight: 400;
                    font-family: $primary-font;
                } 
                &:focus {
                    box-shadow: none;
                } 
               } 
            }
        }
    }

    &-pay {
        &-box {
            background: #FFFFFF;
            border: 0.2px solid #ECA633;
            border-radius: 8.48px;
            filter: drop-shadow(-5px 10px 45px rgba(0, 0, 0, 0.03));
            &-header {
                font-family: $primary-font;
                img {
                    width: 65px;
                    height: 65px;
                    border-radius: 50%;
                }
                h5 {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 27px;
                    line-height: 47px;
                    letter-spacing: -0.02em;
                    color: $main-green;
                }
                h6 {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 25px;
                    line-height: 30px;
                    letter-spacing: -0.02em; 
                    color: #161616;
                }
                p {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 22px;
                    line-height: 18px; 
                    letter-spacing: -0.02em; 
                    color: #585858;
                }
                .cash-box {
                    span {
                        background-color: $main-green;
                        color: #fff;
                        font-weight: 700;
                        font-size: 35px;
                        line-height: 47px;
                        letter-spacing: -0.02em; 
                        padding: 7px 25px;
                    }
                }
            }

            &-body {
                font-family: $primary-font;
                p {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 17px;
                    line-height: 24px;
                    text-align: center;
                    letter-spacing: -0.02em;

                    color: #000000;
                }
                ul {
                    li {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 17px;
                        line-height: 35px;  
                        letter-spacing: -0.02em;
                    }
                }
            }
        }
    }
}

@media (max-width: 576px) {
    .apply { 
        &-header {
            .container { 
                .title-btn { 
                    left: 10%;
                    bottom: 15vh;
                }
            }
        }
    
        &-form {
            &-box { 
                &-header { 
                    h5 { 
                        font-size: 30.0341px;
                        line-height: 40px; 
                    }
                    p {  
                        font-size: 17.6693px;
                        line-height: 32px; 
                    }
                }
                
                &-body {
                   select {  
                    font-size: 16px;
                    line-height: 27px;  
                      
                   } 
                }
            }
        }
    
        &-pay {
            &-box {
                background: #FFFFFF;
                border: 0.2px solid #ECA633;
                border-radius: 8.48px;
                filter: drop-shadow(-5px 10px 45px rgba(0, 0, 0, 0.03));
                &-header {
                    font-family: $primary-font;
                    img {
                        width: 45px;
                        height: 45px;
                    }
                    h5 { 
                        font-size: 24px;
                        line-height: 47px; 
                    }
                    h6 { 
                        font-size: 20px;
                        line-height: 27px; 
                    }
                    p { 
                        font-size: 18px;
                        line-height: 18px;  
                    }
                    .cash-box {
                        span {
                            background-color: $main-green;
                            color: #fff;
                            font-weight: 700;
                            font-size: 30px;
                            line-height: 47px;
                            letter-spacing: -0.02em; 
                            padding: 7px 25px;
                        }
                    }
                }
    
                &-body {
                    font-family: $primary-font;
                    p { 
                        font-size: 16px;
                        line-height: 24px; 
                    }
                    ul {
                        li { 
                            font-size: 16px;
                            line-height: 35px;   
                        }
                    }
                }
            }
        }
    }
}
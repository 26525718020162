.nav {
    background-color: #fff;
    min-height: 80px;
    border-bottom: 3px solid $main-yellow; 
    z-index: 1000;
    .logo {
        img {
            width: 50px;
        }
    }
    &-list {
        ul {
            list-style-type: none;
            margin-bottom: 0;
            padding-left: 0;
            li {
                display: inline-block;
                margin-left: 25px; 
                a {
                    color: #341900;
                    text-decoration: none;
                    font-size: 14px;
                    line-height: 19px;
                    text-transform: capitalize;
                    font-weight: 400;
                    font-family: $primary-font;
                    button {
                        background-color: $main-yellow;
                        padding: 10px 20px;
                        min-width: 70px;
                        min-height: 30px;
                        font-size: 14px;
                        color: white;
                        border: 1px solid $main-yellow;
                        outline: none;
                    }
                }
            }
        }
    }

    .hamburg {
        cursor: pointer;
        display: inline-block;
        position: relative;
        margin: 10px 5px;
        z-index: 20;
        margin-left: 15px;
        background-color: transparent;
        span {
            background-color: $main-yellow;
            // background-color: rgba(91, 106, 119, 0.95);
            display: block;
            height: 0.133em;
            margin-bottom: 0.28em;
            position: relative;
            top: 0;
            transition: all 0.3s ease-in-out;
            &:nth-of-type(1) {
               width: 1.25em;
               margin-left: 2px;
            }
            &:nth-of-type(2) {
                width: 1.8em;
            }
            &:nth-of-type(3) {
                width: 1.5em; 
                margin-left: -5px;
             }
        } 
    }
}

.mobile-nav {
    position: fixed;
    // inset: 0;
    top: 0;
    left: 0;
    width: 100vw;
    height: 120vh;
    background-color: $main-blue;
    z-index: 10000;
    padding: 20px;
    border-top: 3px solid #fff;
    border-bottom: 3px solid #fff;
    transform: translateX(-100%); 
    transition: all 0.3s ease-in;
    .close-hamburg {
        cursor: pointer;
        display: inline-block;
        position: relative;
        margin: 10px 5px; 
        margin-left: 15px;
        background-color: transparent; 
        color: $main-yellow;
        div {
            span { 
                background-color: $main-yellow; 
                display: block;
                height: 0.133em;
                margin-bottom: 0.0em;
                position: relative;
                top: 0;
                transition: all 0.3s ease-in-out;
                &:nth-of-type(1) {
                   width: 1.25em;
                   transform: rotateZ(135deg);
                }
                &:nth-of-type(2) {
                    width: 1.25em;
                    transform: rotateZ(-115deg);
                 }
            } 
        }
    }

    &-content { 
        font-weight: 600;
        letter-spacing: -1px;
        color: #fff;
        &-links {
            ul {
                padding-left: 0;
                list-style-type: none;
                li {
                    margin-top: 5px;
                    a {
                        text-decoration: none;
                        color: #fff;
                        font-size: 22px;
                    }
                }
                
            }
        }

        &-contact {
            .location {
                img {
                    width: 35px;
                }
        
                p {
                    span {
                        display: block;
                        font-family: $nunito;
                        font-weight: 600;
                        font-size: 13.2333px;
                        line-height: 18px; 
                        color: #fff;
                    }
                }
            }
        
            &-social-icons {
                a {
                    img {
                        width: 20px; 
                        opacity: 0.95;
                    }
                }
            }
        
            &-number {
                span { 
                    font-size: 15px;
                    line-height: 22px;  
                }
            }
        
            &-chat {
                img {
                    width: 40px;
                }
        
                p { 
                    font-size: 15px;
                    line-height: 22px;  
                }
            }
        } 
      }  
}

.mobile-nav.active {
    transform: translateX(0);
    transition: all 0.3s ease-in;
}

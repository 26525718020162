.info-section {
    background-color: $main-blue;
    color: #fff;
    font-family: $primary-font;
    z-index: 1000;

    img {
        width: 13px;
    }

    span {
        font-size: 14px;
        font-weight: 700;
        line-height: 21.6px;
        color: #fff;
        margin-bottom: 0;
    }
}
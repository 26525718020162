.footer {
    background-color: #fff;
    text-align: center;
    min-height: 420px;
    &-list {
        list-style-type: none;
        li {
            display: inline-block;
            margin: 10px 20px;
            a {
                font-weight: 400;
                font-size: 16px;
                line-height: 28px; 
                color: #000000;
                text-decoration: none;
                font-family: $filson;
            }
        }
    }

    .location {
        img {
            width: 45px;
        }

        p {
            span {
                display: block;
                font-family: $nunito;
                font-weight: 600;
                font-size: 13.2333px;
                line-height: 18px; 
                color: #000000;
            }
        }
    }

    &-social-icons {
        a {
            img {
                width: 22.67px; 
                opacity: 0.95;
            }
        }
    }

    &-number {
        span {
            font-family: $primary-font;
            font-weight: 700;
            font-size: 15px;
            line-height: 22px; 
            color: #000000;
        }
    }

    &-chat {
        img {
            width: 50px;
        }

        p {
            font-family: $primary-font;
            font-weight: 700;
            font-size: 15px;
            line-height: 22px; 
            color: #000000;
        }
    }

    &-email {
        a {
            font-family: $primary-font;
            font-weight: 600;
            font-size: 17px;
            line-height: 22px; 
            color: #000000;
            text-decoration: none;
        }
    }
}


@media (max-width: 576px) {
    .footer {
        background-color: #fff;
        text-align: center;
        min-height: 350px;
        &-list { 
            li { 
                margin: 10px 15px;
                a { 
                    font-size: 15px;
                    line-height: 24px;  
                }
            }
        }
    
        .location {
            img {
                width: 35px;
            }
    
            p {
                span {
                    display: block;
                    font-family: $nunito;
                    font-weight: 600;
                    font-size: 13.2333px;
                    line-height: 18px; 
                    color: #000000;
                }
            }
        }
    
        &-social-icons {
            a {
                img {
                    width: 20px; 
                    opacity: 0.95;
                }
            }
        }
    
        &-number {
            span { 
                font-size: 15px;
                line-height: 22px;  
            }
        }
    
        &-chat {
            img {
                width: 40px;
            }
    
            p { 
                font-size: 15px;
                line-height: 22px;  
            }
        }
    }
}
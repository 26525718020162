@import './config';
@import './info-section';
@import './nav';
@import './stats';
@import './footer';
@import './about';
@import './study_abroad';
@import './evaluate';
@import './apply';
@import './home';
@import './certification';
@import './contact';
@import './testimonial';

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
}
 

.main header { 
    font-family: $primary-font; 
}

.main-btn {
    outline: none;
    border: none;
    padding: 10px 20px;   
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px; 
    text-transform: capitalize;
    font-family: $primary-font;
}

.big-btn {
    outline: none;
    border: none;
    padding: 10px 20px;   
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 25px; 
    text-transform: capitalize;
    min-height: 60px;
    min-width: 240px;
    font-family: $primary-font;
    border-radius: 4px;
}

.tx-yellow {
    color : $main-yellow;
}

.dropdown-menu{
    left: auto!important;
    top: 95%!important;
    border-radius: 0!important;
    border: 0;
  }
  .dropdown:hover .dropdown-menu, .dropdown:hover .dropdown-menu.sub-dropdown {
    display: block;
    margin-top: 0;
    color: $main-yellow;
  }
 
  .nav-item.dropdown .dropdown-menu a, .nav-item.online-dropdown .dropdown-menu a{
    // font-weight: 600!important;
    padding: 13px;
    font-size: 13px; 
    font-family: $primary-font;
  }
 
  .title-btn { 
    z-index: 100;
    min-width: 300px;  
    position: relative;
    background-color: $main-yellow;
    outline: none;
    border: none;
    color: #fff;
    padding: 10px;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    font-size: 26px;
    min-height: 55px;
    font-weight: 400;
    font-style: normal;
    &::after {
        position: absolute;
        content: "";
        width: 50%; 
        border-top: 1.3px solid $main-yellow;
        border-left: 1.3px solid $main-yellow;
        height: 80%;
        top: -15%;
        left: -20px;
    }
    &::before {
        position: absolute;
        content: "";
        width: 50%; 
        border-bottom: 1.3px solid $main-yellow;
        border-right: 1.3px solid $main-yellow;
        height: 80%;
        bottom: -15%;
        right: -20px;
    }
}


@media (max-width: 576px) {
    .title-btn { 
        z-index: 100;
        min-width: 200px;  
        position: relative;
        background-color: $main-yellow;
        outline: none;
        border: none;
        color: #fff;
        padding: 10px;
        text-transform: uppercase;
        font-size: 20px;
        min-height: 45px;
        &::after {
            position: absolute;
            content: "";
            width: 50%; 
            border-top: 1.3px solid $main-yellow;
            border-left: 1.3px solid $main-yellow;
            height: 80%;
            top: -15%;
            left: -15px;
        }
        &::before {
            position: absolute;
            content: "";
            width: 50%; 
            border-bottom: 1.3px solid $main-yellow;
            border-right: 1.3px solid $main-yellow;
            height: 80%;
            bottom: -15%;
            right: -15px;
        }
    }
}


.error-page {
    background-color: $main-blue;
    
    div {
        background: linear-gradient(135deg, rgba(255, 255, 255, 0.1) 30%, #006b8b 30%, #006b8b 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 80%, #006b8b 80% );
        min-height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #FFF;
    }
    h2 {
        font-size: 2.5rem;
        font-family: $secondary-font;
        text-transform: uppercase;
        font-weight: 900;
    }
    h6 {
        font-family: $primary-font;
        text-transform: capitalize;
        font-size: 1.5rem;
        a {
            color: $main-yellow
        }
    }
}

.back-to-top {
    background-color: $main-yellow;
    width: 45px;
    height: 45px;
    display: block;
    border-radius: 50%;
    border: 1px solid $main-yellow;
    z-index: 100;
    transition: all 0.3s ease-in;
    svg {
        color: #fff;
        font-size: 20px;
        transition: all 0.3s ease-in;
    }
    &:hover {
        scale: 1.05;
        svg {
            margin-top: -10px;
            font-size: 15px; 
        }
    }
}


#myVideo {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
      object-fit: cover;
  }


.loader-box {
    position: absolute;
    inset: 0;
    background-color: rgba($color: #000000, $alpha: 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
}
.loader {
    width: 20px;
    height: 20px;
    display: block;
    background-color: transparent; 
    border-top: 2px solid #fff;
    border-bottom: 2px solid #fff;
    border-radius: 50%;
    animation: loader 1s infinite  ;
}


@keyframes loader {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
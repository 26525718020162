.home {
  overflow-x: hidden;
  &-header {
    min-height: 85vh;
    width: 100vw;
    position: relative;
    .bg {
      background-color: $main-blue;
    }
    .access-btn {
      z-index: 100;
      min-width: 250px;
      margin-left: 150px;
      position: absolute;
      background-color: $main-yellow;
      outline: none;
      border: none;
      color: #fff;
      padding: 10px 20px;
      text-transform: uppercase;
      font-size: 26px;
      &::after {
        position: absolute;
        content: "";
        width: 50%;
        border-top: 1.3px solid $main-yellow;
        border-left: 1.3px solid $main-yellow;
        height: 80%;
        top: -15%;
        left: -20px;
      }
      &::before {
        position: absolute;
        content: "";
        width: 50%;
        border-bottom: 1.3px solid $main-yellow;
        border-right: 1.3px solid $main-yellow;
        height: 80%;
        bottom: -15%;
        right: -20px;
      }
    }
    .swiper-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {
      bottom: 10px;
      left: 0;
      width: 100%;
    }
    .swiper-button-next {
      display: none;
      &::after {
        display: none;
      }
    }
    .swiper-button-prev {
      display: none;
      &::after {
        display: none;
      }
    }
    .swiper-pagination {
      position: absolute;
      text-align: right;
      padding: 30px 80px;
      &-bullet {
        width: 23px;
        height: 23px;
        background: #ffffff;
        opacity: 0.3;
        &-active {
          opacity: 1;
        }
      }
    }
    &-blue-section {
      background: linear-gradient(
        135deg,
        rgba(255, 255, 255, 0.1) 30%,
        #006b8b 30%,
        #006b8b 50%,
        rgba(255, 255, 255, 0.1) 50%,
        rgba(255, 255, 255, 0.1) 80%,
        #006b8b 80%
      );
      height: 85vh;
      color: #fff;
      padding-top: 20vh;
      position: relative;
      h1 {
        font-style: normal;
        font-weight: 900;
        font-size: 32.3787px;
        line-height: 42px;
        text-transform: uppercase;
        color: #ffffff;
        font-family: $secondary-font;
      }
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        text-transform: capitalize;
        color: #ffffff;
      }
      .blue-ring {
        position: absolute;
        width: 60%;
        height: 30%;
        bottom: 0;
        left: 0;
      }

      .cert-badge {
        position: absolute;
        width: 80px;
        bottom: 40px;
        right: 40px;
      }
      .study-ring {
        position: absolute;
        top: -70px;
        left: -30px;
      }
    }
    &-carousel {
      position: relative;
      .slide-div-one {
        background: linear-gradient(
            300.39deg,
            rgba(0, 0, 0, 0.6) 0.63%,
            rgba(0, 0, 0, 0) 18.44%
          ),
          url(../images/canada1.jpg);
        overflow-x: hidden;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-origin: top left;
        height: 100%;
      }
      .slide-div-two {
        background: linear-gradient(
            300.39deg,
            rgba(0, 0, 0, 0.6) 0.63%,
            rgba(0, 0, 0, 0) 18.44%
          ),
          url(../images/uk3.jpg);
        overflow-x: hidden;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-origin: top left;
        height: 100%;
      }
      .slide-div-three {
        background: linear-gradient(
            300.39deg,
            rgba(0, 0, 0, 0.6) 0.63%,
            rgba(0, 0, 0, 0) 18.44%
          ),
          url(../images/australia.jpg);
        overflow-x: hidden;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-origin: top left;
        height: 100%;
      }
    }
  }

  &-header-mobile {
    min-height: 75vh;
    width: 100vw;
    &-carousel {
      position: relative;
      height: 75vh;
      display: flex;
      justify-content: center;
      &-text {
        position: absolute;
        top: 40%;
        z-index: 100;
        &-box {
          background-color: rgba(0, 0, 0, 0.6);
          min-height: 200px;
          color: #fff;
          display: flex;
          justify-content: center;
          flex-direction: column;
          h1 {
            font-style: normal;
            font-weight: 900;
            font-size: 32px;
            line-height: 40px;
            text-transform: uppercase;
            color: #ffffff;
            font-family: $secondary-font;
          }
          p {
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            text-transform: capitalize;
            color: #ffffff;
          }
          .access-btn {
            background-color: $main-yellow;
            outline: none;
            border: none;
            color: #fff;
            padding: 10px;
            text-transform: uppercase;
            font-size: 16px;
            position: relative;
            width: 150px;
          }
        }
      }
      .slide-div-one {
        background: linear-gradient(
            300.39deg,
            rgba(0, 0, 0, 0.6) 0.63%,
            rgba(0, 0, 0, 0) 18.44%
          ),
          url(../images/poland.png);
        overflow-x: hidden;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-origin: top left;
        height: 100%;
      }
      .slide-div-two {
        background: linear-gradient(
            300.39deg,
            rgba(0, 0, 0, 0.6) 0.63%,
            rgba(0, 0, 0, 0) 18.44%
          ),
          url(../images/australia.png);
        overflow-x: hidden;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-origin: top left;
        height: 100%;
      }
      .slide-div-three {
        background: linear-gradient(
            300.39deg,
            rgba(0, 0, 0, 0.6) 0.63%,
            rgba(0, 0, 0, 0) 18.44%
          ),
          url(../images/usa.png);
        overflow-x: hidden;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-origin: top left;
        height: 100%;
      }

      .swiper-horizontal > .swiper-pagination-bullets,
      .swiper-pagination-bullets.swiper-pagination-horizontal,
      .swiper-pagination-custom,
      .swiper-pagination-fraction {
        bottom: 10px;
        left: 0;
        width: 100%;
      }
      .swiper-button-next {
        display: none;
        &::after {
          display: none;
        }
      }
      .swiper-button-prev {
        display: none;
        &::after {
          display: none;
        }
      }
      .swiper-pagination {
        position: absolute;
        top: 0;
        text-align: right;
        padding: 15px 30px;
        &-bullet {
          width: 17px;
          height: 17px;
          background: #ffffff;
          opacity: 0.3;
          &-active {
            opacity: 1;
          }
        }
      }
    }
  }

  .get-started {
    font-family: $primary-font;
    h2 {
      font-style: normal;
      font-weight: 700;
      font-size: 35.2603px;
      line-height: 45px;
      letter-spacing: -0.02em;
      color: #000000;
    }
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 24px;
      text-align: center;
      text-transform: capitalize;
      color: #060606;
    }

    .video-box {
      background-color: rgb(238, 234, 234);
      border-radius: 34.4115px;
      min-height: 400px;
    }
  }

  .benefits {
    background-color: #fafafa;
    font-family: $primary-font;
    .benefit-btn {
      position: absolute;
      top: -5rem;
      right: 0;
    }
    &-box {
      background: #ffffff;
      box-shadow: 0px 10px 45px -2px rgba(36, 36, 36, 0.03);
      border-radius: 40px;
      h6 {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.02em;
        color: #000000;
      }
      &-text {
        h2 {
          font-style: normal;
          font-weight: 700;
          font-size: 32.2603px;
          line-height: 40px;
          letter-spacing: -0.02em;
          color: #000000;
        }
        p {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          text-transform: capitalize;
          color: #060606;
        }
      }
    }
  }

  .free-section {
    background-color: #fafafa;
    &-container {
      .free-card {
        background: #ffffff;
        box-shadow: 0px 10px 45px rgba(36, 36, 36, 0.03);
        border-radius: 40px;
        img {
          border-radius: 40px 40px 0 0;
        }
        &-body {
          font-family: $primary-font;
          h3 {
            font-style: normal;
            font-weight: 700;
            font-size: 42px;
            line-height: 55px;
            letter-spacing: -0.02em;
            color: #0d0d0d;
          }
          ul {
            li {
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              text-transform: capitalize;
              color: #0d0d0d;
            }
          }
        }
      }
    }
  }
  .access-card {
    font-family: $primary-font;
    position: relative;
    padding-top: 150px;
    background-color: $main-yellow;
    box-shadow: 0px 10px 45px -10px rgba(36, 36, 36, 0.03);
    border-radius: 40px;
    color: #fff;
    img.hand {
      width: 50%;
      position: absolute;
      top: 0;
      right: 0;
    }
    h3 {
      font-style: normal;
      font-weight: 700;
      font-size: 42px;
      line-height: 55px;
      letter-spacing: -0.02em;
    }
    p {
      font-weight: 400;
      font-size: 17px;
      line-height: 20px;
      text-transform: capitalize;
    }
  }

  .procedure {
    font-family: $primary-font;
    &-header {
      h2 {
        font-style: normal;
        font-weight: 700;
        font-size: 35.2603px;
        line-height: 45px;
        letter-spacing: -0.02em;
        color: #000000;
      }
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 24px;
        text-align: center;
        text-transform: capitalize;
        color: #060606;
      }
    }
    &-body {
      background-color: rgba(236, 166, 51, 0.06);
      &-box {
        .img-box {
          position: relative;
          img {
            width: 100%;
            position: relative;
          }
          .step {
            position: absolute;
            // left: 25%;
            right: -10%;
            top: -35px;
            // z-index: 100;
          }
        }

        .mySwiper {
          h3 {
            font-style: normal;
            font-weight: 700;
            font-size: 30px;
            line-height: 40px;
            letter-spacing: -0.02em;
            color: #000000;
          }
          p {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-transform: capitalize;
            color: #060606;
          }

          .swiper-horizontal > .swiper-pagination-bullets,
          .swiper-pagination-bullets.swiper-pagination-horizontal,
          .swiper-pagination-custom,
          .swiper-pagination-fraction {
            bottom: 10px;
            left: 0;
            width: 100%;
          }
          .swiper-button-next {
            &::after {
              display: none;
            }
          }
          .swiper-button-prev {
            display: none;
            &::after {
              display: none;
            }
          }
          .swiper-pagination {
            // margin-left: -20px;
            display: flex;
            align-items: flex-start;
            &-bullet {
              width: 14px;
              height: 14px;
              background: #000;
              opacity: 0.08;
              &-active {
                background-color: $main-yellow;
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 771px) {
  .home {
    .procedure {
      &-body {
        &-box {
          .img-box {
            img {
              width: 80%;
            }
            .step { 
                right: 10%; 
            }
          }
          .mySwiper {
            .swiper-pagination {
              margin-left: 0; 
              &-bullet {
                width: 12px;
                height: 12px;
                background: #000;
                opacity: 0.08;
                &-active {
                  background-color: $main-yellow;
                  opacity: 1;
                }
              }
            }

            .swiper-horizontal > .swiper-pagination-bullets,
            .swiper-pagination-bullets.swiper-pagination-horizontal,
            .swiper-pagination-custom,
            .swiper-pagination-fraction {
                bottom: 0;
                left: 0;
                width: 100%;
            }
          }
        }
      }
    }
  }
}

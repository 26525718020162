.about {
    overflow-x: hidden;
    &-header {
        background: linear-gradient(300.39deg, rgba(0, 0, 0, 0.6) 0.63%, rgba(0, 0, 0, 0) 18.44%), url(../images/uk2.jpg);
        height: 75vh;
        width: 100vw;
        // overflow-x: hidden;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-origin: center left; 
        .container {
            position: relative; 
            height: 100%;
            .about-btn {
                position: absolute; 
                z-index: 100;
                left: 0;
                bottom: -2rem;
                // bottom: 0;
            }
        }
    }
    .who-we-are {
        h3 {
            font-style: normal;
            font-weight: 700;
            font-size: 38.2603px;
            line-height: 52px; 
            letter-spacing: -0.02em; 
            color: #000000;
            font-family: $primary-font
        }
        p {
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 32px;  
            text-transform: capitalize; 
            color: #060606;
            font-family: $primary-font;
        }
    }

    .team-section{
        &-text {
            p {
                font-style: normal;
                font-weight: 400;
                font-size: 17px;
                line-height: 32px; 
                color: #060606;
                font-family: $primary-font;
            }
        }
        &-image {
            position: relative;
            .title-btn {
                position: absolute;
                top: -30px;
                right: 10px;
            }
        }
    }

    .client-satisfaction {
        h3 {
            font-style: normal;
            font-weight: 700;
            font-size: 30px;
            line-height: 45px; 
            text-transform: capitalize;
            font-family: $primary-font;
        }
        &-image {
            position: relative;
            text-align: right;
            .title-btn {
                position: absolute;
                bottom: -20px;
                left: 0; 
            }
        }
    }
}

@media (max-width: 576px) {
    .about { 
        &-header { 
            .container { 
                .about-btn { 
                    left: 10%;
                    // bottom: 80px;
                    bottom: 15vh;
                }
            }
        }
        .who-we-are {
            h3 { 
                font-size: 32px;
                line-height: 40px;  
            }
            p { 
                font-size: 15px;
                line-height: 30px;   
            }
        }
    
        .team-section{
            &-text {
                p { 
                    font-size: 15px;
                    line-height: 30px;   
                }
            } 
        }
    
        .client-satisfaction {
            h3 { 
                font-size: 27px;
                line-height: 40px;  
            } 
        }
    }
}
.stats-section {
    background: rgba(242, 252, 255, 0.4);
    &-box { 
        h6 {
            font-weight: 900;
            font-size: 24px;
            line-height: 32px;
            text-transform: capitalize; 
            color: #000D11;
            font-family: $primary-font;
        }
        p {
            font-weight: 400;
            font-size: 14.25px;
            line-height: 19px;
            text-transform: capitalize; 
            color: #000D11;
            font-family: $primary-font;
        }
    }
}
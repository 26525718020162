.evaluate {
    overflow-x: hidden;
    &-header {
        background: linear-gradient(300.39deg, rgba(0, 0, 0, 0.6) 0.63%, rgba(0, 0, 0, 0) 18.44%), url(../images/evaluate.jpg);
        height: 70vh;
        width: 100vw; 
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-origin: top left;
        .container {
            height: 100%;
            position: relative;
            .title-btn {
                position: absolute; 
                bottom: -2rem;
            }
        }
    }

    &-form {
        &-box {
            background: $main-blue; 
            color: #fff;
            border-radius: 7px;
            &-header {
                text-align: center;
                font-family: $primary-font;
                h5 {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 34.0341px;
                    line-height: 51px;
                    letter-spacing: -0.02em;
                }
                p { 
                    font-style: normal;
                    font-weight: 400;
                    font-size: 20.6693px;
                    line-height: 39px;
                    text-align: center;
                    text-transform: capitalize;
                }
            }
            
            &-body { 
               select {
                
                padding: 14.3346px 28.6693px;
                background: rgba(255, 255, 255, 0.1);
                border-radius: 4.30039px;
                border: none;
                color: #fff;
                font-family: $primary-font; 
                font-size: 18.0685px;
                line-height: 27px; 
                text-transform: capitalize; 
                opacity: 0.6;
                option {
                    background-color:$main-yellow;
                    font-style: normal;
                    font-weight: 400;
                    font-family: $primary-font;
                } 
                &:focus {
                    box-shadow: none;
                } 
               } 
            }
        }
    }
}

.evaluate-modal-container {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    .evaluate-modal {
        background-color: #fff;
        font-family: $primary-font;
        color: #000;
        position: relative; 
        .close-btn {
            position: absolute;
            top: 10px;
            right: 10px;
            color: $main-blue;
            font-size: 25px;
        }
        &-header {
            background-color: rgba(236, 166, 51, 0.8);
            padding: 10px;
            h3 {
                font-style: normal;
                font-weight: 700;
                font-size: 35.2603px;
                line-height: 45px; 
                letter-spacing: -0.02em;   
                color: #fff;
            }
            p {
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 22px; 
                text-transform: capitalize; 
                color: #fff;
            }
        }

        &-body {
            p {
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 24px; 
                text-transform: capitalize;  
            }
            form {
                label {
                    margin-bottom: 2px;
                }
                input {
                    padding: 15px 18px;
                    width: 100%;
                    border-radius: 8px;
                    background-color: rgba(237, 237, 237, 0.7); 
                    font-size: 16px;  
                    outline: none; 
                    color: rgba($color: #000000, $alpha: 0.45);
                    line-height: 28.4px;
                    font-weight: 500;
                    border: 1px solid #f5f5f5;
                    font-family: $primary-font;
                    &:focus {
                        box-shadow: none;
                    }
                }
            }
        }
    }
}

@media (max-width: 576px) {
    .evaluate { 
        &-header { 
            .container { 
                .title-btn {
                    left: 10%;
                    bottom: 15vh;
                }
            }
        }
    
        &-form {
            &-box { 
                &-header { 
                    h5 { 
                        font-size: 30.0341px;
                        line-height: 40px; 
                    }
                    p {  
                        font-size: 17.6693px;
                        line-height: 32px; 
                    }
                }
                
                &-body {
                   select {  
                    font-size: 16px;
                    line-height: 27px;  
                      
                   } 
                }
            }
        }
    }

    .evaluate-modal-container {
        height: 100vh;
        width: 100vw;
        background-color: rgba(0, 0, 0, 0.5);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        display: flex;
        align-items: center;
        .evaluate-modal {
            background-color: #fff;
            font-family: $primary-font;
            color: #000;
            position: relative; 
            .close-btn {
                position: absolute;
                top: 10px;
                right: 10px;
                color: red;
                font-size: 25px;
            }
            &-header {
                background-color: rgba(236, 166, 51, 0.8);
                padding: 10px;
                h3 {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 25.2603px;
                    line-height: 35px;  
                }
                p { 
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 20px;  
                }
            }
    
            &-body {
                p { 
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 23px;  
                }
                form { 
                    input {
                        padding: 15px 15px;  
                        font-size: 16px;   
                        line-height: 23.4px; 
                        &:focus {
                            box-shadow: none;
                        }
                    }
                }
            }
        }
    }
}